import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  ListItem,
  // OrderedList,
  UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import Foswig from 'foswig'
import BaseLayout from '../../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const chain = new Foswig(
    3,
    data.wordsJson.spanish.nouns
      .concat(data.wordsJson.spanish.adjectives)
      .concat(data.wordsJson.spanish.verbs)
  )

  const constraints = {
    minLength: 4,
    maxLength: 10,
    allowDuplicates: false,
  }

  const [word, setWord] = useState(chain.generate(constraints))

  const bussinessTypes = ['SL', 'SA', 'SRL', 'LLC', 'Inc', 'Corp']

  const generateBussinessName = () => {
    const bizType =
      bussinessTypes[Math.floor(Math.random() * bussinessTypes.length)]
    const bizName = chain.generate(constraints)
    setWord(
      `${
        bizName.charAt(0).toUpperCase() + bizName.slice(1).toLowerCase()
      } ${bizType}`
    )
  }

  useEffect(() => {
    generateBussinessName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BaseLayout>
      <SEO
        title="Generador de nombres para empresas originales"
        titleSeo="Generador de nombres para empresas originales"
        description="Herramienta para generar nombres para empresas originales para negocios en español."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[{ name: 'Nombres', url: '/nombres/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {word}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de nombres para empresas originales
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() => generateBussinessName()}
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>
          Generador de nombres para empresas originales en espa&ntilde;ol
        </Heading1>
        <Paragraph>
          Abrir una empresa o negocio suele ser un proceso estresante y repleto
          de pasos a seguir para atravesar la burocracia y as&iacute; finalmente
          tener el negocio no solo creado sino adem&aacute;s en funcionamiento.
          Es por esto que disponer de un generador de nombres de empresas
          originales puede suponer un gran ahorro de tiempo y energ&iacute;a,
          que ser&aacute;n mejor utilizados en otras tareas m&aacute;s
          importantes durante la creaci&oacute;n de un comercio o el surgir una
          nueva compa&ntilde;&iacute;a.
        </Paragraph>
        <Paragraph>
          Esto no quiere decir que escoger el nombre de marca correcto no sea
          relevante, porque es el elemento principal (mucho m&aacute;s que el
          logo, por ejemplo) con el cu&aacute;l asociar&aacute;n la identidad de
          nuestra empresa nuestros clientes. As&iacute;, resulta de vital
          importancia escoger correctamente el nombre para nuestra empresa de
          cara al futuro de nuestra marca y el marketing de la misma, pero
          tampoco deber&iacute;a interponerse con el resto de actividades
          necesarias para crear un negocio y ponerlo en marcha.
        </Paragraph>
        <Paragraph>
          Una herramienta que sirva para generar nombres para empresas al azar
          nos permite descubrir nombres creativos y originales que tienen cierta
          familiaridad, pero no son una copia de ning&uacute;n otro. No nos
          interesa escoger un nombre imposible de pronunciar o muy
          dif&iacute;cil de recordar, por ejemplo, dado que por muy original que
          sea, no ser&aacute; nada efecto a efectos de publicitar nuestra nueva
          marca.
        </Paragraph>
        <Heading2>
          Nombres para empresas originales, f&aacute;ciles de recordar y
          pronunciar
        </Heading2>
        <Paragraph>
          Por lo mencionado anteriormente, resulta importante contar con un
          generador de nombres de negocios aleatorios que genere palabras que
          puedan ser utilizadas como marcas comerciales y sean f&aacute;ciles de
          insertar en las campa&ntilde;as de marketing.
        </Paragraph>
        <Paragraph>
          Dicho generador de nombres de compa&ntilde;&iacute;as debe ser capaz
          de generar aleatoriamente palabras originales y creativas, que no sean
          ya conocidas o utilizadas por otras empresas, y que adem&aacute;s
          resulten f&aacute;cil de recordar al toparse con ellas la primera vez,
          para lo cual es de mucha relevancia el que se puede pronunciar sin
          mayor dificultad, no queremos confundir a nuestros futuros clientes
          con marcas que requieren de sonidos extra&ntilde;os a los que no
          est&aacute;n acostumbrados.
        </Paragraph>
        <Paragraph>
          Simplemente juntar letras consonantes y vocales al azar por lo general
          no da muy buenos resultados al intentar generar una marca comercial
          para nuestra nueva empresa, mucho menos si lo intentamos hacer de
          forma manual donde cada combinaci&oacute;n requiere de bastante
          m&aacute;s esfuerzo para ser generada que si simplemente utilizados
          alguna herramienta de software online como este generador de nombres
          para negocios en espa&ntilde;ol, que no solo cuenta con un algoritmo
          de creaci&oacute;n de palabras aleatorios cercanas al idioma
          espa&ntilde;ol y f&aacute;ciles de recordar, sino que adem&aacute;s
          nos permite recorrer cientos, o miles, de opciones en cuesti&oacute;n
          de segundos.
        </Paragraph>
        <Heading3>100 nombres de empresas en espa&ntilde;ol</Heading3>
        <Paragraph>
          M&aacute;s all&aacute; de poder utilizar el generador de nombres de
          empresas en espa&ntilde;ol para generar muchas palabras
          r&aacute;pidamente, muchas veces lo que queremos es leer una
          &ldquo;peque&ntilde;a&rdquo; lista de opciones para luego tomarnos
          nuestro tiempo y crear alguno propio en base a los elementos que hemos
          visto.
        </Paragraph>
        <Paragraph>
          Para esto, adem&aacute;s de ofrecer la generaci&oacute;n de nombres
          para empresa originales, aqu&iacute; dejamos una lista de 100 nombres
          de negocios en espa&ntilde;ol que pueden servirte como base para hacer
          un <em>brainstorming</em> (o &ldquo;lluvia de ideas&rdquo;) por tu
          propia cuenta y crear alguna combinaci&oacute;n o mutaci&oacute;n de
          las opciones de la lista:
        </Paragraph>
        <UnorderedList>
          <ListItem>Elemento</ListItem>
          <ListItem>Elemento</ListItem>
          <ListItem>Elemento</ListItem>
        </UnorderedList>
        <Paragraph>
          La lista no es exhaustiva ni pretende contener los mejores nombres
          para empresas en espa&ntilde;ol, simplemente pretende ser una
          peque&ntilde;a utilidad para hacer una lluvia de ideas sin partir
          desde cero.
        </Paragraph>
        <Paragraph>
          Si lo que quieres es obtener directamente las palabras ya creadas,
          entonces te recomendamos utilizar el generador tantas veces como
          gustes hasta encontrar el nombre perfecto para tu empresa.
        </Paragraph>
        <Heading3>Nombres de empresas inventadas: generador de marcas</Heading3>
        <Paragraph>
          Algo importante a tener en cuenta es que el nombre de tu empresa
          probablemente ser&aacute; la marca comercial bajo la cual har&aacute;s
          todos los tr&aacute;mites y papeles a nivel legal, por lo que no solo
          tiene que ser un creativo y atractivo, sino que adem&aacute;s debe ser
          original, dado que no es posible registrar una marca comercial
          id&eacute;ntica (o excesivamente similar) a alguna otra ya existente.
        </Paragraph>
        <Paragraph>
          Teniendo en cuenta lo f&aacute;cil que es crear una empresa hoy en
          d&iacute;a, no es ninguna sorpresa el hecho de que los nombres
          m&aacute;s f&aacute;ciles de elaborar ya est&aacute;n por lo general
          ocupados y no podremos utilizarlos, por lo que un generador aleatorio
          es perfecto para obtener una lista de nombres de empresas inventadas
          que podremos registrar como persona jur&iacute;dica y comenzar a
          utilizarla de inmediato.
        </Paragraph>
        <Paragraph>
          La originalidad no solo ser&aacute; necesaria para evitar conflictos
          con otras marcas ya inventadas, sino que adem&aacute;s nos
          beneficiar&aacute; con una f&aacute;cil distintos respecto a nuestros
          competidores a ojos de nuestros clientes, despu&eacute;s de todo, no
          queremos que al ver nuestros productos y servicios los confundan con
          los de otra marca, porque as&iacute; ser&aacute; mucho m&aacute;s
          dif&iacute;cil lanzar nuestro negocio al mundo.
        </Paragraph>
        <Heading4>
          La opini&oacute;n de los clientes importa: nombres atractivos para
          negocios
        </Heading4>
        <Paragraph>
          No todo nombre original es un nombre atractivo, simplemente hacer una
          sopa de letras por lo general nos servir&aacute; para dar con muchas
          palabras &ldquo;originales&rdquo; pero nada atractivas para ser
          utilizadas como marca de empresa.
        </Paragraph>
        <Paragraph>
          Como ya hemos mencionado con anterioridad, existen algunas propiedades
          que nos colocar&aacute;n en una posici&oacute;n favorable al momento
          de escoger nombres atractivos para nuestros negocios:
        </Paragraph>
        <UnorderedList>
          <ListItem>
            <strong>Longitud</strong>: los nombres largos son desaconsejables,
            resultan dif&iacute;ciles de recordar. Un aspecto bastante intuitivo
            y evidente.
          </ListItem>
          <ListItem>
            <strong>Debe ser pronunciable</strong>: los clientes deben poder
            utilizar sonidos familiares para referirse a nuestro negocio. Nada
            de mezclar sonidos de otros idiomas si nuestros clientes no lo
            hablan.
          </ListItem>
          <ListItem>
            <strong>Evitar los trabalenguas</strong>: muchas marcas intentan ser
            ingeniosas, pero solo logran dificultar el uso de su nombre.
          </ListItem>
          <ListItem>
            <strong>No ser palabras excesivamente comunes</strong>: algo que
            puede tentarnos mucho es el simplemente utilizar una palabra del
            idioma ya existentes, pero esto solo dificultar&aacute; crear una
            imagen de marca sin producir confusi&oacute;n.
          </ListItem>
        </UnorderedList>
        <Paragraph>
          Podr&iacute;amos seguir agregando &iacute;tems a la lista, pero eso
          solo entorpecer&iacute;a la lectura dado que ya hemos analizado los
          aspectos m&aacute;s importantes al generar nombres de negocios
          atractivos, el resto son nimiedades a las cuales les podemos dar una
          consideraci&oacute;n secundaria.
        </Paragraph>
        <Heading2>Disponibilidad de nombres para empresas</Heading2>
        <Paragraph>
          En l&iacute;neas generales, ser&aacute; f&aacute;cil encontrar nombre
          para empresas disponibles para registrar como marcar comercial siempre
          y cuando utilicemos palabras inventadas y no prestadas de alg&uacute;n
          lugar.
        </Paragraph>
        <Paragraph>
          Pero a&uacute;n as&iacute; podemos enfrentarnos a la situaci&oacute;n
          en la cual, a pesar de ya contar con el nombre ideal para nuestra
          nueva compa&ntilde;&iacute;a, resulta que el nombre ya est&aacute;
          registrado y deberemos recurrir a un plan B.
        </Paragraph>
        <Paragraph>
          El organismo responsable del registro de nombres comerciales
          depender&aacute; del pa&iacute;s y la regi&oacute;n en la que estemos,
          por lo cual no podemos brindar una lista exhaustiva y
          espec&iacute;fica de todos los nombres de empresa que ya no
          est&aacute;n disponibles, pero esto no debe ser motivo de
          preocupaci&oacute;n ya que por lo general es muy f&aacute;cil recurrir
          a la p&aacute;gina web oficial del organismo regulador de nuestra zona
          y chequear online si el nombre para empresa que queremos est&aacute;
          disponible.
        </Paragraph>
        <Heading3>Nombres de empresas comerciales</Heading3>
        <Paragraph>
          Sin importar si buscamos generar beneficios o no, la realidad es que
          cualquier marca est&aacute; sujeta al mismo registro o, en otras
          palabras, no podemos repetir el nombre de otros negocios simplemente
          porque nosotros seamos un tipo de empresa diferente, el nombre de
          marca debe ser original y no estar repetido.
        </Paragraph>
        <Paragraph>
          Da igual que queramos crear una empresa unipersonal o sin fines de
          lucro, si una gran marca comercial ya tiene registrado el nombre que
          deseamos, entonces tendremos que buscar uno diferente.
        </Paragraph>
        <Paragraph>
          Esto tambi&eacute;n incluye a empresas internacionales cuyas marcas
          registradas son respectas a nivel local, no podremos repetir sus
          nombres a pesar de que nuestras bases comerciales est&eacute;n en
          pa&iacute;ses diferentes y alejados.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      spanish {
        nouns
        adjectives
        verbs
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
